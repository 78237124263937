import React, { useState, useEffect } from "react";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
  getStorage,
} from "firebase/storage";
import defaultImage from "../assets/user.png"; // Import default image
import "./Profile.css";

const Profile = () => {
  const [profile, setProfile] = useState({
    "Doctor's Name": "",
    Degree: "",
    Speciality: "",
    Experience: "",
    Fees: "",
    "Hospital's Name": "",
    Address: "",
    Image: null, // Local preview URL
    file: null, // Raw file for Firebase upload
  });
  const [originalProfile, setOriginalProfile] = useState({});
  const [loading, setLoading] = useState(false);
  const [docId, setDocId] = useState(null);

  const db = getFirestore();
  const auth = getAuth();
  const storage = getStorage();

  useEffect(() => {
    const fetchProfile = async () => {
      setLoading(true);
      const user = auth.currentUser;

      if (user) {
        const q = query(
          collection(db, "Doctors"),
          where("Corporate ID", "==", user.email)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const doctorDoc = querySnapshot.docs[0];
          setDocId(doctorDoc.id);
          const data = doctorDoc.data();

          setOriginalProfile(data);

          setProfile((prev) => ({
            ...prev,
            "Doctor's Name": data["Doctor's Name"] || "",
            Degree: data.Degree || "",
            Speciality: data.Speciality || "",
            Experience: data.Experience || "",
            Fees: data.Fees || "",
            "Hospital's Name": data["Hospital's Name"] || "",
            Address: data.Address || "",
            Image: data.Image || null,
          }));
        } else {
          alert("No profile found. Please create a profile.");
        }
      } else {
        console.error("No user is currently logged in.");
      }
      setLoading(false);
    };

    fetchProfile();
  }, [auth, db]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const localUrl = URL.createObjectURL(file);
      setProfile((prev) => ({
        ...prev,
        Image: localUrl,
        file, // Save file for later upload
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    let imageUrl = originalProfile.Image;

    try {
      // Delete the old image if it exists
      if (originalProfile.Image && profile.file) {
        const oldImageRef = ref(storage, originalProfile.Image);
        await deleteObject(oldImageRef).catch((error) => {
          console.error("Error deleting old image:", error);
        });
      }

      // Upload new image if selected
      if (profile.file) {
        const storageRef = ref(storage, `Doctors/${Date.now()}-${profile.file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, profile.file);

        await new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            null,
            reject,
            async () => {
              imageUrl = await getDownloadURL(uploadTask.snapshot.ref);
              resolve();
            }
          );
        });
      }

      // Prepare updated fields
      const updatedFields = {
        ...profile,
        Image: imageUrl,
      };
      delete updatedFields.file; // Remove raw file before updating Firestore

      // Update Firestore
      if (docId) {
        const docRef = doc(db, "Doctors", docId);
        await updateDoc(docRef, updatedFields);
        alert("Profile updated successfully!");
        setOriginalProfile(updatedFields); // Sync with original
      } else {
        alert("No document ID found for update.");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("An error occurred while updating the profile.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="profile-container">
      <h3>Profile</h3>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <form onSubmit={handleSubmit}>
          {Object.entries(profile).map(([key, value]) =>
            key === "Image" || key === "file" ? null : (
              <div key={key}>
                <label>{key}:</label>
                <input
                  type="text"
                  name={key}
                  value={value || ""}
                  onChange={handleChange}
                />
              </div>
            )
          )}
          <div>
            <label>Profile Image:</label>
            <div className="image-container">
              <img
                src={profile.Image || defaultImage}
                alt="Profile"
                width={100}
              />
              <input type="file" accept="image/*" onChange={handleImageChange} />
            </div>
          </div>

          <div>
            <button type="submit" disabled={loading}>
              {loading ? "Updating..." : "Save Profile"}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default Profile;
