import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth } from '../firebase';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import './Login.css';

const Login = () => {
  const navigate = useNavigate();
  const db = getFirestore(); // Initialize Firestore
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (event) => {
    event.preventDefault();
    setError('');

    try {
      // Check if the user exists in the "Doctors" collection with Corporate ID and userRole "Doctor"
      const doctorsCollection = collection(db, 'Doctors');
      const q = query(doctorsCollection, where('Corporate ID', '==', email));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setError('Invalid Corporate ID. Contact Cureclix support team.');
        return;
      }

      const userData = querySnapshot.docs[0].data();
      if (userData.userRole !== 'Doctor') {
        setError('You are not authorized to log in to this panel. Contact Cureclix support.');
        return;
      }

      // If checks pass, proceed with Firebase Authentication
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/dashboard'); // Navigate to the Dashboard after successful login
    } catch (error) {
      console.error('Error signing in:', error);
      setError('Invalid Corporate ID or password. Please try again.');
    }
  };

  const handleForgotPassword = async (event) => {
    event.preventDefault();
    if (!email) {
      setError('Please enter your Corporate ID to reset your password.');
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      alert('Password reset email sent! Please check your inbox.');
    } catch (error) {
      console.error('Error sending password reset email:', error);
      setError('Failed to send password reset email. Please try again.');
    }
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleLogin}>
        <h1 className="login-header">Login Here</h1>
        {error && <p className="error-message" style={{ color: 'red' }}>{error}</p>}

        <div className="form-group">
          <input
            type="email"
            id="email"
            placeholder="Enter your Corporate ID"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="form-group" style={{ marginTop: '20px' }}>
          <input
            type="password"
            id="password"
            placeholder="Enter your password"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="links">
          <p onClick={handleForgotPassword} className="forgot-password">
            Forgot Password?
          </p>
        </div>
        <button type="submit" className="login-button">Login</button>
        <div className="signup-prompt">
          <p className="signup-text">Don't have an account? <Link to="/signup" className="signup">Sign Up</Link></p>
        </div>
      </form>
    </div>
  );
};

export default Login;
