import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="footer-col">
              <h4>Know Us</h4>
              <ul>
                <li><Link to="/about-us" aria-label="About Us">About Us</Link></li>
                <li><Link to="/services" aria-label="Our Services">terms & Conditions</Link></li>
                <li><Link to="/privacy-policy" aria-label="Privacy Policy">Privacy Policy</Link></li>
                <li><Link to="/DataDelete-policy" aria-label="Data Deletion Policy">Data Delete</Link></li>
              </ul>
            </div>
            <div className="footer-col">
              <h4>Get Help</h4>
              <ul>
                <li><Link to="/products" aria-label="Our Products">Our Products</Link></li>
                <li><Link to="/pricing" aria-label="Our Pricing">Our Pricing</Link></li>
                <li><Link to="/payment" aria-label="Make Payment">Make Payment</Link></li>
                <li><Link to="/support" aria-label="Contact Support">Contact Support</Link></li>
              </ul>
            </div>
            <div className="footer-col">
              <h4>Login Options</h4>
              <ul>
                <li><Link to="/login/doctor" aria-label="Doctor Login">Doctor</Link></li>
                <li><Link to="/login/reception" aria-label="Reception Login">Reception</Link></li>
                <li><Link to="/login/medical" aria-label="Medical Login">Medical</Link></li>
                <li><Link to="/login/test-imaging" aria-label="Test and Imaging Login">Test & Imaging</Link></li>
              </ul>
            </div>
            <div className="footer-col">
              <h4>Follow Us</h4>
              <div className="social-links">
                <a href="#" aria-label="Follow us on Facebook"><FontAwesomeIcon icon={faFacebookF} /></a>
                <a href="#" aria-label="Follow us on Twitter"><FontAwesomeIcon icon={faTwitter} /></a>
                <a href="#" aria-label="Follow us on Instagram"><FontAwesomeIcon icon={faInstagram} /></a>
                <a href="#" aria-label="Follow us on LinkedIn"><FontAwesomeIcon icon={faLinkedinIn} /></a>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Cureclix. All rights reserved.</p>
      </div>
    </>
  );
}

export default Footer;
