import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth, firestore } from '../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc, collection } from 'firebase/firestore';
import './Signup.css';

const Signup = () => {
  const navigate = useNavigate();
  const [organization, setOrganization] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [doctorName, setDoctorName] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSignUp = async () => {
    if (!/^\d{10}$/.test(mobileNumber)) {
      setError('Mobile number must be exactly 10 digits');
      return;
    }

    setLoading(true);
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const userId = userCredential.user.uid;

      const DoctorID = doc(collection(firestore, 'Doctors')).id; // Generate unique Doctor ID

      // Save to 'Doctors' collection
      await setDoc(doc(firestore, 'Doctors', DoctorID), {
        userRole: 'NA', // Save userRole in Doctors collection
        DoctorID, // Unique Doctor ID
        'Corporate ID': email, // Store the authenticated user's email as 'Corporate ID'
        mobileNumber,
        Address: address,
        "Hospital's Name": organization,
        "Doctor's Name": doctorName,
        category: 'NA', 
      });

      setSuccess('Registration successful! You can now log in.');
      setTimeout(() => navigate('/login'), 3000); // Redirect to login after 3 seconds
    } catch (error) {
      console.error('Error creating user:', error);
      switch (error.code) {
        case 'auth/email-already-in-use':
          setError('Email already in use. Please sign in instead.');
          break;
        case 'auth/invalid-email':
          setError('The email address is not valid.');
          break;
        case 'auth/weak-password':
          setError('The password is too weak. Please choose a stronger password.');
          break;
        default:
          setError('Registration failed. Please check your credentials.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setError('');
    setSuccess('');
    handleSignUp();
  };

  return (
    <div className="signup-container">
      <form className="signup-form" onSubmit={handleSubmit}>
        <h1 className="signup-header">Sign Up Here</h1>
        {loading && <div className="progress-bar"></div>}
        <div className="form-group">
          <input
            type="text"
            placeholder="Hospital/Clinic Name"
            value={organization}
            onChange={(e) => {
              setOrganization(e.target.value);
              setError('');
            }}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Enter Doctor's Name"
            value={doctorName}
            onChange={(e) => {
              setDoctorName(e.target.value);
              setError('');
            }}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Enter Your Address"
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
              setError('');
            }}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="tel"
            placeholder="Enter Mobile Number"
            value={mobileNumber}
            onChange={(e) => {
              setMobileNumber(e.target.value);
              setError('');
            }}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setError('');
            }}
            required
          />
        </div>
        <div className="form-group">
          <input
            type="password"
            placeholder="Create a password"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setError('');
            }}
            required
          />
        </div>

        {error && <p className="error-message" style={{ color: 'red' }}>{error}</p>}
        {success && <p className="success-message" style={{ color: 'green' }}>{success}</p>}

        <button type="submit" className="signup-button" disabled={loading}>
          {loading ? 'Signing Up...' : 'Sign Up'}
        </button>
        <div className="signup-prompt">
          <p className="signup-text">
            Already have an account? <Link to="/login" className="signup">Log In</Link>
          </p>
        </div>
      </form>
    </div>
  );
};

export default Signup;
