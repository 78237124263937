import React from 'react';
import './DataDeletion.css';


const DataDeletion = () => {
  return (
    <div className="data-deletion-policy">
      <h1>Data Deletion Policy</h1>

      <h2>Introduction</h2>
      <p>
        This Data Deletion Policy outlines the process for users to request the deletion of their personal data collected by our app. We are committed to protecting your privacy and respecting your control over your information.
      </p>

      <h2>What Data We Collect</h2>
      <p>We collect various types of data depending on how you use our app. This may include:</p>
      <ul>
        <li>Account information (username, email address)</li>
        <li>Usage data (app features used)</li>
        <li>Health data (depending on app functionality)</li>
      </ul>

      <h2>Data Retention</h2>
      <p>We retain your data for as long as necessary to fulfill the purposes outlined in our Privacy Policy. This may include:</p>
      <ul>
        <li>Providing and maintaining the functionalities of our app</li>
        <li>Analyzing usage data to improve the app</li>
        <li>Complying with legal and regulatory requirements</li>
      </ul>

      <h2>Data Deletion Request</h2>
      <p>You have the right to request the deletion of your personal data at any time. To submit a deletion request, you can:</p>
      <ul>
        <li>Contact us through email at <a href="mailto:cureclix@gmail.com">cureclix@gmail.com</a></li>
      </ul>

      <h2>Processing of Deletion Requests</h2>
      <p>We will process your data deletion request within a reasonable timeframe, typically within 30 business days. Once your request is processed, your personal data will be deleted from our systems and any backups in accordance with our data security practices.</p>

      <h2>Exceptions to Data Deletion</h2>
      <p>There may be certain circumstances where we are unable to delete all or part of your data. This may include:</p>
      <ul>
        <li>When the data is required to comply with legal or regulatory obligations</li>
        <li>When the data is anonymized and no longer personally identifiable</li>
      </ul>
      <p>We will inform you of any such exceptions in our response to your deletion request.</p>

      <h2>Verification of Deletion Request</h2>
      <p>To protect your privacy and security, we may require verification of your identity before processing your data deletion request. This may involve requesting additional information to confirm your account ownership.</p>

      <h2>Data Deletion and Backups</h2>
      <p>
        While we strive to delete your data completely from our systems, there may be residual copies due to backups. These backups are encrypted and regularly overwritten following a defined retention schedule.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions about this Data Deletion Policy or your right to data deletion, please feel free to contact us:
      </p>
      <p>Email: <a href="mailto:cureclix@gmail.com">cureclix@gmail.com</a></p>

      <h2>Changes to this Policy</h2>
      <p>
        We may update this Data Deletion Policy from time to time. We will notify you of any significant changes by posting the updated policy on our website or within the app.
      </p>

      <p>This policy is intended to be clear and comprehensive. If you have any questions, please don’t hesitate to contact us.</p>
    </div>
  );
};

export default DataDeletion;
