import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => {
    return (
        <div className="privacy-policy">
            <h1>Privacy Policy</h1>

            <section>
                <h2>Introduction</h2>
                <p>
                    Welcome to CureClix. This Privacy Policy outlines the data collection, use, and disclosure practices of the CureClix app, a free service provided by CureClix. By using the app, you agree to the terms and conditions of this policy.
                </p>
            </section>

            <section>
                <h2>Information Collection and Use</h2>
                <p>
                    The app may request certain personally identifiable information from users, including name, phone number, email address, and physical address. This information will be used to provide and improve our services.
                </p>
                <p>
                    The app utilizes third-party services (Google Play Services, Google Analytics for Firebase, Firebase Crashlytics, and Facebook) that may also collect user information for specific purposes.
                </p>
            </section>

            <section>
                <h2>Log Data</h2>
                <p>
                    The app collects Log Data in case of errors or issues within the app. This data may include the user’s device IP address, device name, operating system version, app configuration, time and date of app usage, and other statistics.
                </p>
            </section>

            <section>
                <h2>Cookies</h2>
                <p>
                    The app itself does not use cookies explicitly. However, third-party code and libraries used in the app may use cookies to enhance their services. Users can choose to accept or refuse cookies.
                </p>
            </section>

            <section>
                <h2>Service Providers</h2>
                <p>
                    Third-party companies and individuals may be employed to facilitate, provide, or analyze the app’s services. These service providers may have access to users’ Personal Information but are not allowed to disclose or use it for other purposes.
                </p>
            </section>

            <section>
                <h2>Security</h2>
                <p>
                    The app strives to use commercially acceptable means to protect users’ Personal Information. However, no method of transmission over the internet or electronic storage is 100% secure, and the app cannot guarantee absolute security.
                </p>
            </section>

            <section>
                <h2>Links to Other Sites</h2>
                <p>
                    The app may contain links to third-party websites. Users are advised to review the Privacy Policy of these external sites, as the app has no control over their content, privacy policies, or practices.
                </p>
            </section>

            <section>
                <h2>Children’s Privacy</h2>
                <p>
                    The app does not collect personally identifiable information from individuals under the age of 13. If the app becomes aware that a child under 13 has provided personal information, it will be immediately deleted from the servers.
                </p>
            </section>

            <section>
                <h2>Changes to the Privacy Policy</h2>
                <p>
                    The Privacy Policy may be updated periodically. Users are advised to review this page for any changes. Changes will be notified by posting the new Privacy Policy on the app.
                </p>
            </section>

            <section>
                <h2>Contact Us</h2>
                <p>
                    If you have any questions about this Privacy Policy, please contact us at cureclix@gmail.com.
                </p>
            </section>
        </div>
    );
};

export default PrivacyPolicy;
