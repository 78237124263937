import { useParams } from "react-router-dom";

const Patient = () => {
  const { patientId } = useParams(); // Get patientId from the URL

  // Fetch patient details using patientId
  // For example, you can use useEffect to fetch data from Firestore

  return (
    <div>
      <h1>Patient Details for {patientId}</h1>
      {/* Render patient details here */}
    </div>
  );
};

export default Patient;
