import React, { useState, useEffect } from "react";
import { Line, Bar, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  BarElement,
  ArcElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "./Data.css";
import { collection, query, where, getDocs } from "firebase/firestore";
import { firestore, auth } from "../firebase";

// Register chart.js components
ChartJS.register(
  LineElement,
  BarElement,
  ArcElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const Data = () => {
  const [timeframe, setTimeframe] = useState("monthly");
  const [specificPeriod, setSpecificPeriod] = useState(
    `${new Date().getMonth() + 1}/${new Date().getFullYear()}`
  );
  const [currentMonth] = useState(new Date().getMonth() + 1);
  const [currentYear] = useState(new Date().getFullYear());
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  const [flowData, setFlowData] = useState({
    monthly: { labels: [], datasets: [] },
    annual: { labels: [], datasets: [] },
  });
  
  const [peakTimeData, setPeakTimeData] = useState({
    labels: [],
    datasets: [],
  });
  

  const fetchAppointments = async () => {
    try {
      const user = auth.currentUser;
      if (!user) throw new Error("User not authenticated.");

      const email = user.email;
      const doctorSnapshot = await getDocs(
        query(collection(firestore, "Doctors"), where("Corporate ID", "==", email))
      );

      if (doctorSnapshot.empty) throw new Error("No doctor document found.");
      const doctorId = doctorSnapshot.docs[0].get("DoctorID");

      const appointmentsSnapshot = await getDocs(
        query(
          collection(firestore, "Booking"),
          where("doctorId", "==", doctorId),
          where("status", "in", ["Visited", "Confirm"])
        )
      );

      return appointmentsSnapshot.docs.map((doc) => doc.data());
    } catch (error) {
      console.error("Error fetching appointments:", error.message);
      return [];
    }
  };

  const updateChartData = async () => {
    const appointments = await fetchAppointments();
  
    // Process for Flow Chart
    const flowCountsVisited = {};
    const flowCountsConfirmed = {};
    const peakTimeCounts = {
      "8 AM - 10 AM": 0,
      "10 AM - 12 PM": 0,
      "12 PM - 2 PM": 0,
      "2 PM - 4 PM": 0,
      "4 PM - 6 PM": 0,
      "6 PM - 8 PM": 0,
    };
  
    if (timeframe === "monthly") {
      const [month, year] = specificPeriod.split("/").map(Number);
      appointments.forEach(({ date, time, status }) => {
        const [day, m, y] = date.split("/").map(Number);
        if (m === month && y === year) {
          if (status === "Visited") {
            flowCountsVisited[day] = (flowCountsVisited[day] || 0) + 1;
          } else if (status === "Confirm") {
            flowCountsConfirmed[day] = (flowCountsConfirmed[day] || 0) + 1;
          }
  
          // Process for Peak Time
          const slot = getTimeSlot(time);
          peakTimeCounts[slot] = (peakTimeCounts[slot] || 0) + 1;
        }
      });
  
      const daysInMonth = new Date(year, month, 0).getDate();
      const flowLabels = Array.from({ length: daysInMonth }, (_, i) => (i + 1).toString());
      const flowDataValuesVisited = flowLabels.map((day) => flowCountsVisited[day] || 0);
      const flowDataValuesConfirmed = flowLabels.map((day) => flowCountsConfirmed[day] || 0);
  
      setFlowData({
        monthly: {
          labels: flowLabels,
          datasets: [
            {
              label: "Daily Patient Flow (Visited)",
              data: flowDataValuesVisited,
              backgroundColor: "#28a745",
              borderColor: "#28a745", // Green for "Visited"
              borderWidth: 1,
            },
            {
              label: "Daily Patient Flow (Confirmed)",
              data: flowDataValuesConfirmed,
              backgroundColor: "#36a2eb",
              borderColor: "#007bff", // Blue for "Confirmed"
              borderWidth: 1,
            },
          ],
        },
      });
    } else if (timeframe === "annual") {
      const year = Number(specificPeriod);
      appointments.forEach(({ date, time, status }) => {
        const [_, m, y] = date.split("/").map(Number);
        if (y === year) {
          if (status === "Visited") {
            flowCountsVisited[m] = (flowCountsVisited[m] || 0) + 1;
          } else if (status === "Confirm") {
            flowCountsConfirmed[m] = (flowCountsConfirmed[m] || 0) + 1;
          }
  
          // Process for Peak Time
          const slot = getTimeSlot(time);
          peakTimeCounts[slot] = (peakTimeCounts[slot] || 0) + 1;
        }
      });
  
      const flowLabels = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December",
      ];
      const flowDataValuesVisited = flowLabels.map((_, index) => flowCountsVisited[index + 1] || 0);
      const flowDataValuesConfirmed = flowLabels.map((_, index) => flowCountsConfirmed[index + 1] || 0);
  
      setFlowData({
        annual: {
          labels: flowLabels,
          datasets: [
            {
              label: "Monthly Patient Flow (Visited)",
              data: flowDataValuesVisited,
              backgroundColor: "#36a2eb",
              borderColor: "#28a745", // Green for "Visited"
              borderWidth: 1,
            },
            {
              label: "Monthly Patient Flow (Confirmed)",
              data: flowDataValuesConfirmed,
              backgroundColor: "#36a2eb",
              borderColor: "#007bff", // Blue for "Confirmed"
              borderWidth: 1,
            },
          ],
        },
      });
    }
  
    // Update Peak Time Chart
    setPeakTimeData({
      labels: Object.keys(peakTimeCounts),
      datasets: [
        {
          label: "Appointments by Peak Times",
          data: Object.values(peakTimeCounts),
          backgroundColor: ["#ff6384", "#36a2eb", "#ffce56", "#4bc0c0", "#9966ff", "#f77825"],
        },
      ],
    });
  };
  

  const getTimeSlot = (time) => {
    const [hour] = time.split(":").map(Number);
    if (hour >= 8 && hour < 10) return "8 AM - 10 AM";
    if (hour >= 10 && hour < 12) return "10 AM - 12 PM";
    if (hour >= 12 && hour < 14) return "12 PM - 2 PM";
    if (hour >= 14 && hour < 16) return "2 PM - 4 PM";
    if (hour >= 16 && hour < 18) return "4 PM - 6 PM";
    if (hour >= 18 && hour < 20) return "6 PM - 8 PM";
    return "Other";
  };

  useEffect(() => {
    updateChartData();
  }, [timeframe, specificPeriod]);

  const handleTimeframeChange = (e) => {
    const newTimeframe = e.target.value;
    setTimeframe(newTimeframe);

    if (newTimeframe === "monthly") {
      setSpecificPeriod(`${new Date().getMonth() + 1}/${new Date().getFullYear()}`);
      setSelectedMonth(new Date().getMonth() + 1);
    } else {
      setSpecificPeriod(`${new Date().getFullYear()}`);
    }
  };

  const handleMonthChange = (e) => {
    const newMonth = e.target.value;
    setSelectedMonth(newMonth);
    setSpecificPeriod(`${newMonth}/${selectedYear}`);
  };

  const handleYearChange = (e) => {
    const newYear = e.target.value;
    setSelectedYear(newYear);
    const month = timeframe === "monthly" ? selectedMonth : null;
    setSpecificPeriod(timeframe === "monthly" ? `${month}/${newYear}` : `${newYear}`);
  };

  return (
    <div>
      <h2>Data Analysis</h2>
      <div className="timeframe-selector">
        <label htmlFor="timeframe">Select Timeframe: </label>
        <select id="timeframe" onChange={handleTimeframeChange} value={timeframe}>
          <option value="monthly">Monthly</option>
          <option value="annual">Annual</option>
        </select>

        {timeframe === "monthly" && (
          <>
            <select id="year" onChange={handleYearChange} value={selectedYear}>
              <option value={currentYear}>{currentYear}</option>
              <option value={currentYear + 1}>{currentYear + 1}</option>
            </select>
            <select id="month" onChange={handleMonthChange} value={selectedMonth}>
              {[
                "January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December",
              ].map((month, index) => (
                <option key={month} value={index + 1}>
                  {month}
                </option>
              ))}
            </select>
          </>
        )}

        {timeframe === "annual" && (
          <select id="year" onChange={handleYearChange} value={specificPeriod}>
            <option value="">Select Year</option>
            {["2024", "2025"].map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        )}
      </div>

      <div className="chart-container">
  {flowData[timeframe]?.labels?.length ? (
    <Line data={flowData[timeframe]} />
  ) : (
    <p>No data available for the selected timeframe.</p>
  )}
</div>

      <div className="chart-container">
  {peakTimeData.labels?.length ? (
    <Bar data={peakTimeData} />
  ) : (
    <p>No data available for peak times.</p>
  )}
</div>

    </div>
  );
};

export default Data;
