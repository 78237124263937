import React, { useState, useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  setDoc,
   doc
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { v4 as uuidv4 } from "uuid"; // Generate unique IDs
import "./Booking.css"; // To style the form

function Booking() {
  const [phoneNumber, setPhoneNumber] = useState("+91");
  const [familyMembers, setFamilyMembers] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showAddMemberForm, setShowAddMemberForm] = useState(false);
  const [newMemberData, setNewMemberData] = useState({
    name: "",
    age: "",
    sex: "",
    address: "",
    guardian: "",
    relation: "",
  });
  const [department, setDepartment] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [reference, setReference] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [doctorData, setDoctorData] = useState(null);
  const [showCreateAccountForm, setShowCreateAccountForm] = useState(false);


  const db = getFirestore();
  const auth = getAuth();

  // **Helper Functions**
  const isValidPhoneNumber = (number) => /^\+91\d{10}$/.test(number);

  const handleProceed = async (e) => {
    e.preventDefault();
    if (!isValidPhoneNumber(phoneNumber)) {
      setErrorMessage("Please enter a valid phone number.");
      return;
    }
    setErrorMessage("");
  
    try {
      const user = auth.currentUser;
      if (!user) {
        setErrorMessage("User not authenticated.");
        return;
      }
  
      const email = user.email;
      const doctorQuery = query(
        collection(db, "Doctors"),
        where("Corporate ID", "==", email)
      );
      const doctorSnapshot = await getDocs(doctorQuery);
      if (doctorSnapshot.empty) {
        setErrorMessage("No doctor found with the given email.");
        return;
      }
  
      // Fetch doctor's data
      doctorSnapshot.forEach((doc) => {
        setDoctorData({
          doctorId: doc.id,
          ...doc.data(),
        });
      });
  
      // Check if account exists
      const accountQuery = query(
        collection(db, "Account"),
        where("phone", "==", phoneNumber)
      );
      const accountSnapshot = await getDocs(accountQuery);
  
      if (accountSnapshot.empty) {
        setShowCreateAccountForm(true); // Open Create Account Form if no account found
        setErrorMessage("");
        return;
      }
  
      // Get the main account holder's data
      const accountData = accountSnapshot.docs[0].data();
      const mainMember = {
        memberId: accountData.memberId, // Use ,member ID as the unique identifier
        name: accountData.name,
        age: accountData.age,
        sex: accountData.sex, // Aligning with "sex" field used in account creation
        guardian: accountData.guardian,
        relation: "Self", // Default to "Self" for the main account holder
        familyId: accountData.familyId,
        address: accountData.address, // Include address for consistency
      };
  
      // Fetch family members associated with the familyId
      const familyQuery = query(
        collection(db, "FamilyMembers"),
        where("familyId", "==", accountData.familyId)
      );
      const familySnapshot = await getDocs(familyQuery);
  
      // Map family member data and include the main account holder
      const members = [
        mainMember, // Add the main account holder
        ...familySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })),
      ];
  
      setFamilyMembers(members);
      setShowModal(true); // Show family members modal
  
    } catch (error) {
      setErrorMessage("Error fetching data. Please try again.");
      console.error(error);
    }
  };
  
  


  const handleCreateAccount = async (e) => {
    e.preventDefault();
  
    const { name, address, age, sex, guardian } = newMemberData;
  
    if (!name || !address || !age || !sex || !guardian) {
      setErrorMessage("All fields are required.");
      return;
    }
  
    try {
      const accountId = phoneNumber; // Use phone number as the document ID
      const newAccountRef = doc(db, "Account", accountId);
  
      const familyId = `FAMILY_${Date.now()}`; // Generate a unique familyId
      const memberId = `MEMBER_${Date.now()}`; // Generate a unique memberId
  
      // Create a new account document
      await setDoc(newAccountRef, {
        phone: phoneNumber,
        name,
        address,
        age,
        sex,
        guardian,
        familyId,
        memberId, // Unique memberId
        relation: "Self", // Default relation
      });
  
      const newMember = {
        id: newAccountRef.id,
        name,
        age,
        sex,
        guardian,
        relation: "Self",
        familyId,
        address,
        memberId, // Include memberId in the local state
      };
  
      // Update state and proceed to the appointment form
      setFamilyMembers([newMember]); // Set the new account holder as the sole family member
      handleMemberSelect(newMember); // Automatically select the new account holder
      setShowCreateAccountForm(false); // Close the account creation modal
    } catch (error) {
      setErrorMessage("Error creating account. Please try again.");
      console.error("Error creating account:", error);
    }
  };
  
  const handleAddMember = async (e) => {
    e.preventDefault();
    const { name, age, sex, guardian, relation } = newMemberData;
  
    if (!name || !age || !sex || !guardian || !relation) {
      setErrorMessage("All fields must be filled out.");
      return;
    }
  
    try {
      // Fetch address from the parent account
      const accountQuery = query(
        collection(db, "Account"),
        where("phone", "==", phoneNumber)
      );
      const accountSnapshot = await getDocs(accountQuery);
      if (accountSnapshot.empty) {
        setErrorMessage("No account found for the provided phone number.");
        return;
      }
      const accountData = accountSnapshot.docs[0].data();
      const address = accountData.address;
  
      // Generate unique familyId if not already present
      const familyId = familyMembers[0]?.familyId || uuidv4();
  
      // Generate unique memberId
      const timestamp = Date.now(); // Current timestamp in milliseconds
      const memberId = `MEMBER_${timestamp}`;
  
      // Add new document with memberId as document ID
      const newMemberRef = doc(db, "FamilyMembers", memberId);
      await setDoc(newMemberRef, {
        name,
        age,
        sex,
        guardian,
        relation,
        familyId,
        memberId, // Use memberId as both field and document ID
        address, // Include the address field
      });

     const newMember = {
        id: memberId, // Use memberId as Firestore document ID
        name,
        age,
        sex,
        guardian,
        relation,
        familyId,
        memberId, // Include memberId in the local state
        address, // Include the address in the local state
      };
  
      setFamilyMembers((prev) => [...prev, newMember]);
      setNewMemberData({
        name: "",
        age: "",
        sex: "",
        guardian: "",
        relation: "",
      });
      setShowAddMemberForm(false);
      setShowModal(false);
      handleMemberSelect(newMember); // Select the new member automatically
    } catch (error) {
      setErrorMessage("Error adding new member. Please try again.");
      console.error("Error adding new member:", error);
    }
  };
  

  const handleMemberSelect = (member) => {
    setSelectedMember(member);
    setShowModal(false);
  };

 const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!department || !date || !time || !reference) {
      setErrorMessage("All fields must be filled out.");
      return;
    }
  
    try {
      // Generate a unique appointmentId
      const appointmentId = uuidv4();
  
      // Convert date to DD/MM/YYYY format
      const formattedDate = new Date(date).toLocaleDateString("en-GB");
  
      // Get current date and time
      const currentDate = new Date().toLocaleDateString("en-GB");
      const currentTime = new Date().toLocaleTimeString();
  
      // Ensure valid data from doctorData
      const hospitalName = doctorData?.hospitalName || doctorData["Hospital's Name"] || "Unknown Hospital";
      const hospitalAddress = doctorData?.hospitalAddress || doctorData["Address"] || "Unknown Address";
      const doctor = doctorData?.doctor || doctorData["Doctor's Name"] || "Unknown Doctor";
  
      // Add data to the Booking collection in Firestore
      const bookingRef = collection(db, "Booking");
      await addDoc(bookingRef, {
        phoneNumber: phoneNumber,
        name: selectedMember.name,
        age: selectedMember.age,
        sex: selectedMember.sex,
        relation: "NA",
        department,
        date: formattedDate, // Save the formatted date
        time,
        refer: reference, // Save the reference as refer
        status: "Confirm",
        familyId: selectedMember.familyId,
        memberId: selectedMember.memberId,
        doctorId: doctorData?.doctorId,
        appointmentId,
        currentDate,
        currentTime,
        hospitalName,
        hospitalAddress,
        doctor,
      });
  
      alert("Appointment booked successfully!");
      setPhoneNumber("+91");
      setFamilyMembers([]);
      setSelectedMember(null);
      setDepartment("");
      setDate("");
      setTime("");
      setReference("");
    } catch (error) {
      setErrorMessage(`Error saving booking. Please try again. Error details: ${error.message}`);
    }
  };


  // **Render**
  return (
    <div className="container mt-5 booking-form">
      <div className="card shadow p-4">
        <h2 className="text-center mb-4">Book an Appointment</h2>

        <form onSubmit={handleProceed}>
          <div className="form-group mb-3">
            <label htmlFor="phone">Phone Number</label>
            <PhoneInput
              id="phone"
              value={phoneNumber}
              onChange={setPhoneNumber}
              className="form-control"
              defaultCountry="IN"
              required
            />
          </div>
          <Button type="submit" className="btn btn-primary w-100">
            Proceed
          </Button>
        </form>

        {errorMessage && (
          <div className="alert alert-danger mt-3">{errorMessage}</div>
        )}

        {selectedMember && (
          <form onSubmit={handleSubmit} className="mt-4">
            <div className="form-group mb-3">
              <label>Full Name</label>
              <input
                type="text"
                className="form-control"
                value={selectedMember.name}
                readOnly
              />
            </div>
            <div className="form-group mb-3">
              <label>Department</label>
              <select
                className="form-control"
                value={department}
                onChange={(e) => setDepartment(e.target.value)}
                required
              >
                <option value="">Select Department</option>
                <option value="General Medicine">General Medicine</option>
                <option value="Cardiology">Cardiology</option>
                <option value="Orthopedics">Orthopedics</option>
                    <option value="Pediatrics">Pediatrics</option>
                    <option value="Gynecology">Gynecology</option>
                    <option value="Obstetrics">Obstetrics</option>
                    <option value="Dermatology">Dermatology</option>
                    <option value="Gastroenterology">Gastroenterology</option>
                    <option value="Pulmonology">Pulmonology</option>
                    <option value="Oncology">Oncology</option>
                    <option value="Nephrology">Nephrology</option>
                    <option value="Endocrinology ">Endocrinology </option>
                    <option value="Psychiatry">Psychiatry</option>
                    <option value="Rheumatology">Rheumatology</option>
                    <option value="Urology">Urology</option>
                    <option value="Ophthalmology">Ophthalmology</option>
                    <option value="ENT (Otorhinolaryngology)">ENT (Otorhinolaryngology)</option>
                    <option value="Hematology">Hematology</option>
                    <option value="Infectious Disease">Infectious Disease</option>
                    <option value="Geriatrics">Geriatrics</option>
                    <option value="Surgery">Surgery</option>
                    <option value="Dentistry">Dentistry</option>
              </select>
            </div>
            <div className="form-group mb-3">
              <label>Appointment Date</label>
              <input
                type="date"
                className="form-control"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                required
              />
            </div>
            <div className="form-group mb-3">
              <label>Appointment Time</label>
              <input
                type="time"
                className="form-control"
                value={time}
                onChange={(e) => setTime(e.target.value)}
                required
              />
            </div>
            <div className="form-group mb-3">
              <label>Reference</label>
              <input
                type="text"
                className="form-control"
                value={reference}
                onChange={(e) => setReference(e.target.value)}
                required
              />
            </div>
            <Button type="submit" className="btn btn-success w-100">
              Book Appointment
            </Button>
          </form>
        )}
      </div>

      {showCreateAccountForm && (
  <Modal show={showCreateAccountForm} onHide={() => setShowCreateAccountForm(false)}>
    <Modal.Header closeButton>
      <Modal.Title>Create a New Account</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <form onSubmit={handleCreateAccount}>
        <div className="form-group mb-3">
          <label>Name</label>
          <input
            type="text"
            className="form-control"
            value={newMemberData.name}
            onChange={(e) => setNewMemberData({ ...newMemberData, name: e.target.value })}
            required
          />
        </div>
        <div className="form-group mb-3">
          <label>Address</label>
          <input
            type="text"
            className="form-control"
            value={newMemberData.address}
            onChange={(e) => setNewMemberData({ ...newMemberData, address: e.target.value })}
            required
          />
        </div>
        <div className="form-group mb-3">
          <label>Age</label>
          <input
            type="number"
            className="form-control"
            value={newMemberData.age}
            onChange={(e) => setNewMemberData({ ...newMemberData, age: e.target.value })}
            required
          />
        </div>
        <div className="form-group mb-3">
          <label>Gender</label>
          <select
            className="form-control"
            value={newMemberData.sex}
            onChange={(e) => setNewMemberData({ ...newMemberData, sex: e.target.value })}
            required
          >
            <option value="">Select Gender</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div className="form-group mb-3">
          <label>Guardian</label>
          <input
            type="text"
            className="form-control"
            value={newMemberData.guardian}
            onChange={(e) => setNewMemberData({ ...newMemberData, guardian: e.target.value })}
            required
          />
        </div>
        <Button type="submit" className="btn btn-primary w-100">
          Create Account
        </Button>
      </form>
    </Modal.Body>
  </Modal>
)}

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {showAddMemberForm ? "Add a New Family Member" : "Select a Family Member"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {showAddMemberForm ? (
            <form onSubmit={handleAddMember}>
              <div className="form-group mb-2">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={newMemberData.name}
                  onChange={(e) =>
                    setNewMemberData({ ...newMemberData, name: e.target.value })
                  }
                  required
                />
              </div>
              <div className="form-group mb-2">
                <label>Age</label>
                <input
                  type="number"
                  className="form-control"
                  value={newMemberData.age}
                  onChange={(e) =>
                    setNewMemberData({ ...newMemberData, age: e.target.value })
                  }
                  required
                />
              </div>
              <div className="form-group mb-2">
                <label>Gender</label>
                <select
                  className="form-control"
                  value={newMemberData.sex}
                  onChange={(e) =>
                    setNewMemberData({ ...newMemberData, sex: e.target.value })
                  }
                  required
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="form-group mb-2">
                <label>Guardian</label>
                <input
                  type="text"
                  className="form-control"
                  value={newMemberData.guardian}
                  onChange={(e) =>
                    setNewMemberData({ ...newMemberData, guardian: e.target.value })
                  }
                  required
                />
              </div>
              <div className="form-group mb-2">
                <label>Relation</label>
                <input
                  type="text"
                  className="form-control"
                  value={newMemberData.relation}
                  onChange={(e) =>
                    setNewMemberData({ ...newMemberData, relation: e.target.value })
                  }
                  required
                />
              </div>
              <Button type="submit" className="btn btn-primary w-100">
                Add Member
              </Button>
              <Button
                className="btn btn-secondary w-100 mt-2"
                onClick={() => setShowAddMemberForm(false)}
              >
                Cancel
              </Button>
            </form>
          ) : (
            <>
              {familyMembers.map((member) => (
                <Button
                  key={member.id}
                  className="btn btn-outline-primary w-100 my-2"
                  onClick={() => handleMemberSelect(member)}
                >
                  {member.name} ({member.age} years old)
                </Button>
              ))}
              <Button
                className="btn btn-outline-success w-100 my-2"
                onClick={() => setShowAddMemberForm(true)}
              >
                Add Member
              </Button>
            </>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Booking;
