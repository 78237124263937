import React, { useState, useEffect } from "react";
import "./AppointmentStatus.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Status from "./Status";
import { FaFileDownload } from "react-icons/fa";
import { collection, query, where, getDocs } from "firebase/firestore";
import { auth, firestore } from "../firebase";

const AppointmentStatus = ({ onNavigate }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [appointmentData, setAppointmentData] = useState({
    total: 0,
    visited: 0,
    waiting: 0,
    pending: 0,
    rejected: 0,
    appointments: [],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchPopup, setShowSearchPopup] = useState(false);

  const appointmentsPerPage = 20;

  const formatDate = (date) => {
    return `${date.getDate().toString().padStart(2, "0")}/${
      (date.getMonth() + 1).toString().padStart(2, "0")
    }/${date.getFullYear()}`;
  };

  const fetchAppointments = async () => {
    setLoading(true);
    try {
      const user = auth.currentUser;
      if (!user) {
        console.error("User not authenticated.");
        return;
      }

      const email = user.email;
      const doctorSnapshot = await getDocs(
        query(collection(firestore, "Doctors"), where("Corporate ID", "==", email))
      );

      if (doctorSnapshot.empty) {
        console.error("No doctor document found.");
        return;
      }

      const doctorId = doctorSnapshot.docs[0].get("DoctorID");
      const appointmentQuery = query(
        collection(firestore, "Booking"),
        where("doctorId", "==", doctorId),
        where("date", "==", formatDate(selectedDate))
      );

      const appointmentSnapshot = await getDocs(appointmentQuery);
      const filteredAppointments = appointmentSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setAppointmentData({
        total: filteredAppointments.length,
        visited: filteredAppointments.filter((a) => a.status === "Visited").length,
        waiting: filteredAppointments.filter((a) => a.status === "Confirm").length,
        pending: filteredAppointments.filter((a) => a.status === "Pending").length,
        rejected: filteredAppointments.filter((a) => a.status === "Rejected").length,
        appointments: filteredAppointments,
      });
    } catch (error) {
      console.error("Failed to fetch appointment data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Search function to filter appointments
  const handleSearch = () => {
    if (searchQuery.trim() === "") {
      setSearchResults([]);
      setShowSearchPopup(false);
      return; // No action if search query is empty
    }

    const results = appointmentData.appointments.filter((appointment) =>
      appointment.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setSearchResults(results);
    setShowSearchPopup(true);
  };

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const downloadAppointments = () => {
    const data = appointmentData.appointments.map((appointment) => ({
      Patient: appointment.name,
      Age: appointment.age,
      Gender: appointment.sex,
      Phone: appointment.phoneNumber,
      Reference: appointment.refer,
      FollowUp: appointment.next,
      Status: appointment.status,
      Date: appointment.date,
      Time: appointment.time,
    }));

    const csvContent =
      "data:text/csv;charset=utf-8," +
      "Patient,Age,Gender,Phone,Reference,FollowUp,Status,Date,Time\n" +
      data
        .map(
          (e) =>
            `${e.Patient},${e.Age},${e.Gender},${e.Phone},${e.Reference},${e.FollowUp},${e.Status},${e.Date},${e.Time}`
        )
        .join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `appointments_${formatDate(selectedDate)}.csv`);
    document.body.appendChild(link);
    link.click();
  };

  useEffect(() => {
    fetchAppointments();
  }, [selectedDate]);

  return (
    <div className="appointment-status">
      <h2>Appointment Status</h2>

      <div className="header-row">
        <div
          className="date-container"
          onClick={() => setShowDatePicker(!showDatePicker)}
        >
          <p className="date-text">Date: {formatDate(selectedDate)}</p>
        </div>

        <div className="appointment-stats">
          {["total", "visited", "waiting", "pending", "rejected"].map((stat) => (
            <div className="stat-item" key={stat}>
              <span className="stat-label">
                {stat.charAt(0).toUpperCase() + stat.slice(1)}:{" "}
              </span>
              <span className="stat-value">{appointmentData[stat]}</span>
            </div>
          ))}
        </div>

        <button
          className="add-appointment-btn"
          onClick={() => onNavigate("Appointments")}
        >
          + Add Appointment
        </button>
      </div>

      {showDatePicker && (
        <DatePicker
          selected={selectedDate}
          onChange={(date) => {
            setSelectedDate(date);
            setShowDatePicker(false);
          }}
          inline
          dateFormat="dd/MM/yyyy"
        />
      )}

      <div className="action-row">
        <div className="search-section">
          <div className="search-container">
            <input
              type="text"
              className="search-input"
              placeholder="Search patient by name"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button className="search-btn" onClick={handleSearch}>
              Search
            </button>
            <FaFileDownload className="icon download-icon" onClick={downloadAppointments} />
          </div>
        </div>
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <Status
          selectedDate={selectedDate}
          formatDate={formatDate}
          currentPage={currentPage}
          appointmentsPerPage={appointmentsPerPage}
        />
      )}

      <div className="pagination">
        {Array.from(
          {
            length: Math.ceil(
              appointmentData.appointments.length / appointmentsPerPage
            ),
          },
          (_, index) => index + 1
        ).map((pageNumber) => (
          <button
            key={pageNumber}
            className={`page-btn ${currentPage === pageNumber ? "active" : ""}`}
            onClick={() => handlePageChange(pageNumber)}
          >
            {pageNumber}
          </button>
        ))}
      </div>

      {showSearchPopup && (
        <div className="search-popup" style={{ position: "absolute", top: "50px", left: "50%", transform: "translateX(-50%)" }}>
          <h3>Search Results</h3>
          {searchResults.length > 0 ? (
            <table className="search-results-table">
              <thead>
                <tr>
                  <th>Patient</th>
                  <th>Age</th>
                  <th>Gender</th>
                  <th>Phone</th>
                  <th>Reference</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {searchResults.map((result) => (
                  <tr key={result.id}>
                    <td>{result.name}</td>
                    <td>{result.age}</td>
                    <td>{result.sex}</td>
                    <td>{result.phoneNumber}</td>
                    <td>{result.refer}</td>
                    <td>{result.date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No results found.</p>
          )}
          <button onClick={() => setShowSearchPopup(false)}>Close</button>
        </div>
      )}
    </div>
  );
};

export default AppointmentStatus;
