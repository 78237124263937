import React, { useState, useEffect } from "react";
import { collection, query, where, onSnapshot, getDocs, getDoc, updateDoc, setDoc, doc, deleteDoc } from "firebase/firestore";
import { auth, firestore } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import "./Status.css";
import { MdDelete } from "react-icons/md";
import { v4 as uuidv4 } from 'uuid'; // Import uuid to generate unique appointment IDs



const Status = ({ selectedDate, formatDate, currentPage, appointmentsPerPage }) => {
  const [appointments, setAppointments] = useState([]);
  const [error, setError] = useState(null);
  const [showPhonePopup, setShowPhonePopup] = useState({ visible: false, phoneNumber: "" });
  const [showStatusPopup, setShowStatusPopup] = useState({
    visible: false,
    currentStatus: "",
    docId: "",
  });
  const [showTimePicker, setShowTimePicker] = useState({ visible: false, docId: "", currentTime: "" });
  const [showFollowUpPopup, setShowFollowUpPopup] = useState({ visible: false, docId: "", currentFollowUp: "" });
  const [selectAll, setSelectAll] = useState(false); // State for the header checkbox
  const [selectedItems, setSelectedItems] = useState(new Set()); // State for individual checkboxes


  useEffect(() => {
    const fetchAppointments = async () => {
      const user = auth.currentUser;
      if (!user) {
        setError("User not authenticated.");
        return;
      }
  
      try {
        const email = user.email;
  
        // Fetch doctor details
        const doctorSnapshot = await getDocs(
          query(collection(firestore, "Doctors"), where("Corporate ID", "==", email))
        );
  
        if (doctorSnapshot.empty) {
          setError("No doctor document found.");
          return;
        }
  
        const doctorId = doctorSnapshot.docs[0].get("DoctorID");
  
        // Fetch all appointments for this doctor
        const allAppointmentsSnapshot = await getDocs(
          query(collection(firestore, "Booking"), where("doctorId", "==", doctorId))
        );
  
        // Create a map to count visits for each memberId
        const memberVisits = {};
        allAppointmentsSnapshot.docs.forEach((doc) => {
          const { memberId, status } = doc.data();
          if (status === "Visited") {
            memberVisits[memberId] = (memberVisits[memberId] || 0) + 1;
          }
        });
  
        // Fetch appointments for the selected date
        const appointmentQuery = query(
          collection(firestore, "Booking"),
          where("doctorId", "==", doctorId),
          where("date", "==", formatDate(selectedDate))
        );
  
        const unsubscribe = onSnapshot(appointmentQuery, async (snapshot) => {
          const fetchedAppointments = [];
          if (!snapshot.empty) {
            for (const doc of snapshot.docs) {
              const appointmentData = doc.data();
              const memberId = appointmentData.memberId;
  
              // Fetch additional member details
              const { guardian, address, profilePicture } = await fetchMemberDetails(memberId);
  
              // Calculate visit count from the memberVisits map
              const visitCount = memberVisits[memberId] || "New";
              const profilePic = profilePicture || require('../assets/user.png'); // Adjust the path as needed
  
              fetchedAppointments.push({
                docId: doc.id,
                serialNumber: doc.id,
                profilePicture: profilePic,
                name: appointmentData.name,
                address: address || appointmentData.address,
                phoneNumber: appointmentData.phoneNumber,
                status: appointmentData.status || "Confirm",
                guardian,
                reference: appointmentData.refer,
                disease: appointmentData.disease,
                time: appointmentData.time || "Not Set",
                age: appointmentData.age,
                gender: appointmentData.sex,
                department: appointmentData.department,
                followUp: appointmentData.next || "NA",
                visit: visitCount, // Use the calculated visit count
                memberId,
              });
            }
  
            // Sort and update state
            fetchedAppointments.sort((a, b) => a.time.localeCompare(b.time));

            const appointmentsWithSerial = fetchedAppointments.map((appointment, index) => ({
              ...appointment,
              serialNumber: index + 1,
            }));

            setAppointments(appointmentsWithSerial);
          } else {
            setAppointments([]);
          }
        });

        return unsubscribe;
      } catch (error) {
        setError("Error fetching appointments.");
      }
    };

    fetchAppointments();
  }, [selectedDate]); 

  
  
// Function to format a date into DD/MM/YYYY format
const formatToInputDate = (date) => {
  const d = new Date(date);
  const day = d.getDate().toString().padStart(2, "0"); // Add leading zero if necessary
  const month = (d.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-indexed, so add 1
  const year = d.getFullYear();

  return `${day}/${month}/${year}`;
};



  const fetchMemberDetails = async (memberId) => {
    let guardian = "";
    let address = "";
    let profilePicture = "";

    const accountSnapshot = await getDocs(
      query(collection(firestore, "Account"), where("memberId", "==", memberId))
    );

    if (!accountSnapshot.empty) {
      const accountDoc = accountSnapshot.docs[0].data();
      guardian = accountDoc.guardian || "";
      address = accountDoc.address || "";
      profilePicture = accountDoc.image_url || "";
    } else {
      const familyMemberSnapshot = await getDocs(
        query(collection(firestore, "FamilyMembers"), where("memberId", "==", memberId))
      );

      if (!familyMemberSnapshot.empty) {
        const familyMemberDoc = familyMemberSnapshot.docs[0].data();
        guardian = familyMemberDoc.guardian || "";
        address = familyMemberDoc.address || "";
        profilePicture = familyMemberDoc.image_url || "";
      }
    }

    return { guardian, address, profilePicture };
  };



// Handle the "Select All" checkbox click
const handleSelectAll = () => {
  if (!selectAll) {
    // Select all items
    const allIds = appointments.map((appointment) => appointment.docId);
    setSelectedItems(new Set(allIds));
  } else {
    // Deselect all items
    setSelectedItems(new Set());
  }
  setSelectAll(!selectAll);
};

// Handle individual checkbox click
const handleCheckboxClick = (docId) => {
  setSelectedItems((prevSelected) => {
    const updatedSelected = new Set(prevSelected);
    if (updatedSelected.has(docId)) {
      updatedSelected.delete(docId);
    } else {
      updatedSelected.add(docId);
    }
    // Update the "Select All" checkbox based on selection
    if (updatedSelected.size === appointments.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
    return updatedSelected;
  });
};

// Handle the "Follow-Up" button click
const handleFollowUpClick = (docId, currentFollowUp) => {
  const formattedDate = currentFollowUp ? formatToInputDate(currentFollowUp) : "";
  setShowFollowUpPopup({
    visible: true,
    docId,
    currentFollowUp: formattedDate,
  });
};
// Update the popup state when the follow-up date input changes
const handleFollowUpChange = (event) => {
  setShowFollowUpPopup((prevState) => ({
    ...prevState,
    currentFollowUp: event.target.value,
  }));
};

const handleConfirmFollowUp = async () => {
  const { docId, currentFollowUp } = showFollowUpPopup;

  if (!currentFollowUp) {
    console.error("Follow-up date is required.");
    return;
  }

  try {
    const appointmentRef = doc(firestore, "Booking", docId);

    // Convert currentFollowUp to Date object and format it as DD/MM/YYYY
    const followUpDate = new Date(currentFollowUp);
    const formattedDate = followUpDate.toLocaleDateString('en-GB'); // This will give DD/MM/YYYY format

    // Update the original document with the new follow-up date in formatted form
    await updateDoc(appointmentRef, { next: formattedDate });

    // Fetch the existing document after update
    const appointmentSnapshot = await getDoc(appointmentRef);
    if (appointmentSnapshot.exists()) {
      const appointmentData = appointmentSnapshot.data()

      // Check if a duplicate already exists
      const duplicateQuery = query(
        collection(firestore, "Booking"),
        where("originalDocId", "==", docId)
      );
      const duplicateSnapshot = await getDocs(duplicateQuery);

      if (!duplicateSnapshot.empty) {
        // If a duplicate exists, update both the nextDate and date fields
        const duplicateDoc = duplicateSnapshot.docs[0];
        const duplicateRef = duplicateDoc.ref;
        await updateDoc(duplicateRef, {
          nextDate: formattedDate, // Update the new follow-up date field
          date: formattedDate,     // Also update the date field in the duplicate
        });
            } else {
        // If no duplicate exists, create a new one with the necessary fields
        const newAppointmentId = uuidv4(); // Generate a new unique appointment ID

        const duplicateData = {
          appointmentId: newAppointmentId, // Assign the unique appointment ID
          familyId: appointmentData.familyId,
          hospitalAddress: appointmentData.hospitalAddress,
          hospitalName: appointmentData.hospitalName,
          memberId: appointmentData.memberId,
          department: appointmentData.department,
          name: appointmentData.name,
          phoneNumber: appointmentData.phoneNumber,
          sex: appointmentData.sex,
          status: "Confirm", // Set status to "Confirm" by default
          time: appointmentData.time,
          doctorId: appointmentData.doctorId,
          doctor: appointmentData.doctor,
          age: appointmentData.age,
          originalDocId: docId, // Reference to the original document
          date: formattedDate, // The new follow-up date field
          createdAt: new Date().toISOString(), // Add a timestamp for duplication
        };

        // Create a duplicate in Firestore
        const duplicateRef = doc(collection(firestore, "Booking"));
        await setDoc(duplicateRef, duplicateData);
      }
    } else {
      console.error("Appointment not found.");
    }

    setShowFollowUpPopup({ visible: false, docId: "", currentFollowUp: "" });
  } catch (error) {
    console.error("Error updating follow-up date and duplicating appointment:", error);
  }
};

// Close the follow-up popup without saving changes
const handleCloseFollowUpPopup = () => {
  setShowFollowUpPopup({ visible: false, docId: "", currentFollowUp: "" });
};

 // Function to determine the Delete Function

 const handleDeleteAppointment = async (docId, status) => {
  try {
    // Check if password verification is required based on status
    if (status === "Confirm" || status === "Visited") {
      const user = auth.currentUser; // Get the current authenticated user
      if (!user) {
        alert("No authenticated user found. Please login again.");
        return;
      }

      // Prompt the user with their email and ask for the password
      const password = prompt(`Your email: ${user.email}\nEnter your password to confirm deletion:`);

      if (!password) {
        alert("Password is required for deletion.");
        return;
      }

      // Verify the user's credentials
      try {
        // Re-authenticate using email and password
        await signInWithEmailAndPassword(auth, user.email, password);
      } catch (error) {
        // Detailed error logging
        console.error("Authentication error:", error);  // Log the full error object
        if (error.code === "auth/wrong-password") {
          alert("Incorrect password. Deletion canceled.");
        } else if (error.code === "auth/user-not-found") {
          alert("User not found. Please login again.");
        } else if (error.code === "auth/too-many-requests") {
          alert("Too many attempts. Please try again later.");
        } else {
          alert(`An error occurred during authentication: ${error.message}`);
        }
        return;
      }
    }

    // Show confirmation dialog
    const userConfirmed = window.confirm("Are you sure you want to delete this appointment?");
    if (!userConfirmed) {
      return; // Exit the function if the user cancels
    }

    // Proceed with deletion
    await deleteDoc(doc(firestore, "Booking", docId));
    setAppointments((prevAppointments) =>
      prevAppointments.filter((appointment) => appointment.docId !== docId)
    );
    alert("Appointment deleted successfully.");
  } catch (error) {
    console.error("Error deleting appointment:", error);
    alert("An error occurred while deleting the appointment. Please try again.");
  }
};


  // Function to determine the color associated with appointment status
  const getStatusColor = (status) => {
    switch (status) {
      case "Pending":
        return "purple";
      case "Confirm":
        return "blue";
      case "Rejected":
        return "red";
      case "Visited":
        return "green";
      default:
        return "gray";
    }
  };

  // Functions related to managing the time picker popup
  const handleTimeClick = (docId, currentTime) => {
    setShowTimePicker({ visible: true, docId, currentTime });
  };

  const handleTimeChange = (event) => {
    setShowTimePicker((prevState) => ({
      ...prevState,
      currentTime: event.target.value,
    }));
  };

  const handleConfirmTime = async () => {
    const { docId, currentTime } = showTimePicker;
    try {
      const appointmentRef = doc(firestore, "Booking", docId);
      await updateDoc(appointmentRef, { time: currentTime });
      setShowTimePicker({ visible: false, docId: "", currentTime: "" });
    } catch (error) {
      console.error("Error updating time:", error);
    }
  };

  const handleCloseTimePicker = () => {
    setShowTimePicker({ visible: false, docId: "", currentTime: "" });
  };

  // Functions related to phone number popup actions
  const handlePhoneClick = (phoneNumber) => {
    setShowPhonePopup({ visible: true, phoneNumber });
  };

  const handleClosePhonePopup = () => {
    setShowPhonePopup({ visible: false, phoneNumber: "" });
  };

  const handleWhatsApp = (phoneNumber) => {
    window.open(`https://wa.me/${phoneNumber}`, "_blank");
  };

  const handleCall = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`;
  };

  // Functions related to managing the status popup
  const handleStatusClick = (currentStatus, docId) => {
    if (currentStatus === "Visited") {
      return; 
    }
  
    setShowStatusPopup({
      visible: true,
      currentStatus,
      docId,
    });
  };

  const handleStatusChange = async (newStatus) => {
    const { docId } = showStatusPopup;
    try {
      const appointmentRef = doc(firestore, "Booking", docId);
      await updateDoc(appointmentRef, { status: newStatus });
      setShowStatusPopup({ visible: false, currentStatus: "", docId: "" });
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleCloseStatusPopup = () => {
    setShowStatusPopup({ visible: false, currentStatus: "", docId: "" });
  };

  // Display error message if any
  if (error) {
    return <p className="error">{error}</p>;
  }

  const indexOfLastAppointment = currentPage * appointmentsPerPage;
  const indexOfFirstAppointment = indexOfLastAppointment - appointmentsPerPage;
  const currentAppointments = appointments.slice(
    indexOfFirstAppointment,
    indexOfLastAppointment
  );
  // Handle page change
const handlePageChange = (pageNumber) => {
  setCurrentPage(pageNumber);
};

  // Main return block rendering the appointments table
  const totalPages = Math.ceil(appointments.length / appointmentsPerPage);
return (
  <div className="appointments-container">
  <div className="table-wrapper">
    {appointments.length > 0 ? (
      <table className="appointments-table">
        <thead>
          <tr>
            <th><input type="checkbox"
             checked={selectAll}
             onChange={handleSelectAll} /></th>
            <th>Sr.</th>
            <th>Patient</th>
            <th>Age</th>
            <th>Gender</th>
            <th>Address</th>
            <th>Phone Number</th>
            <th>Status</th>
            <th>Guardian</th>
            <th>Reference</th>
            <th>Department</th>
            <th>Time</th>
            <th>Follow Up</th>
            <th>Visit</th> {/* New Visit Column */}
            <th>More</th>
          </tr>
        </thead>
        <tbody>
          {currentAppointments.map((appointment, index) => (
            <tr key={appointment.serialNumber}>
               <td>
                  <input
                    type="checkbox"
                    checked={selectedItems.has(appointment.docId)}
                    onChange={() => handleCheckboxClick(appointment.docId)}
                  />
                </td>
              <td>{appointment.serialNumber}</td>
              <td>
                <div className="patient-info">
                  <img
                    src={appointment.profilePicture || "/default-profile.png"}
                    alt="Profile"
                    className="profile-picture"
                  />
                  <span>{appointment.name}</span>
                </div>
              </td>
              <td>{appointment.age}</td>
              <td>{appointment.gender}</td>
              <td>{appointment.address}</td>
              <td
                onClick={() => handlePhoneClick(appointment.phoneNumber)}
                className="phone-number"
              >
                {appointment.phoneNumber}
              </td>
              <td
                onClick={() => handleStatusClick(appointment.status, appointment.docId)}
                style={{ color: getStatusColor(appointment.status) }}
              >
                {appointment.status}
              </td>
              <td>{appointment.guardian}</td>
              <td>{appointment.reference}</td>
              <td>{appointment.department}</td>
              <td
                onClick={() => handleTimeClick(appointment.docId, appointment.time)}
                className="time-cell"
              >
                {appointment.time}
              </td>
              <td
                onClick={() => handleFollowUpClick(appointment.docId, appointment.followUp)}
                className="clickable"
              >
                {appointment.followUp}
              </td>
              <td>{appointment.visit}</td>
              <td>
                <MdDelete
                  onClick={() => handleDeleteAppointment(appointment.docId, appointment.status)}
                  className="delete-icon"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <p>No appointments found for the selected date.</p>
    )}
  </div>


   
      {/* Follow-Up Popup */}
    {showFollowUpPopup.visible && (
      <div className="popup">
        <div className="popup-content">
          <h3>Update Follow-Up Date</h3>
          <label htmlFor="followUpDate">Select Follow-Up Date:</label>
          <input
            type="date"
            id="followUpDate"
            value={showFollowUpPopup.currentFollowUp}
            onChange={handleFollowUpChange}
          />
          <div className="popup-buttons">
            <button onClick={handleConfirmFollowUp}>Confirm</button>
            <button onClick={handleCloseFollowUpPopup}>Cancel</button>
          </div>
        </div>
      </div>
    )}

    {/* Time Picker Popup */}
    {showTimePicker.visible && (
        <div className="popup">
          <div className="popup-content">
            <input
              type="time"
              value={showTimePicker.currentTime}
              onChange={handleTimeChange}
            />
            <button onClick={handleConfirmTime}>Confirm</button>
            <button onClick={handleCloseTimePicker}>Close</button>
          </div>
        </div>
      )}


    {/* Phone Number Popup */}
    {showPhonePopup.visible && (
        <div className="popup">
          <div className="popup-content">
            <button onClick={() => handleCall(showPhonePopup.phoneNumber)}>📞 Call</button>
            <button onClick={() => handleWhatsApp(showPhonePopup.phoneNumber)}>💬 WhatsApp</button>
            <button onClick={handleClosePhonePopup}>Close</button>
          </div>
        </div>
      )}


    {/* Status Popup */}
    {showStatusPopup.visible && (
        <div className="popup">
          <div className="popup-content">
            <p>Current Status: {showStatusPopup.currentStatus}</p>
            <div className="status-options">
              {["Pending", "Confirm", "Rejected", "Visited"].map((status) => (
                status !== showStatusPopup.currentStatus && (
                  <button key={status} onClick={() => handleStatusChange(status)}>
                    {status}
                  </button>
                )
              ))}
            </div>
            <button onClick={handleCloseStatusPopup}>Close</button>
          </div>
        </div>
      )}
    </div>
  ); 

};


export default Status;
