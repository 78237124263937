import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FiLogOut } from "react-icons/fi";
import { auth, firestore } from "../firebase"; // Ensure the correct path to firebase.js
import { collection, query, where, onSnapshot } from "firebase/firestore";
import "./Top.css";

const Top = ({ isSidebarCollapsed }) => {
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
  const [doctorName, setDoctorName] = useState("Hello Doctor");
  const [profileImage, setProfileImage] = useState("https://via.placeholder.com/40");
  const navigate = useNavigate();

  useEffect(() => {
    const user = auth.currentUser;
    if (!user) return;

    const email = user.email;

    // Real-time listener for doctor details
    const doctorQuery = query(
      collection(firestore, "Doctors"),
      where("Corporate ID", "==", email)
    );

    const unsubscribe = onSnapshot(doctorQuery, (querySnapshot) => {
      if (!querySnapshot.empty) {
        const doctorData = querySnapshot.docs[0].data();
        setDoctorName(doctorData["Doctor's Name"] || "Doctor");
        setProfileImage(doctorData.Image || "https://via.placeholder.com/40");
      } else {
        console.error("Doctor document not found.");
      }
    }, (error) => {
      console.error("Error listening to doctor details:", error.message);
    });

    // Cleanup listener on component unmount
    return () => unsubscribe();
  }, []);

  const handleLogout = () => {
    navigate("/"); // Redirect to homepage
  };

  return (
    <div
      className={`top ${isSidebarCollapsed ? "collapsed" : "expanded"}`}
      style={{
        marginLeft: isSidebarCollapsed ? "75px" : "225px",
        transition: "margin-left 0.3s ease",
      }}
    >
      <div
        className={`profile-section ${isSidebarCollapsed ? "expanded" : ""} d-flex align-items-center`}
      >
        <img
          src={profileImage}
          alt="Profile"
          className="profile-img me-2"
        />
        <span className="username">{doctorName}</span>
      </div>
      <div className="logout-section">
        {showLogoutConfirm ? (
          <div className="logout-confirm">
            <span>Are you sure?</span>
            <div className="buttons-container">
              <button onClick={handleLogout} className="confirm-btn">
                Yes
              </button>
              <button
                onClick={() => setShowLogoutConfirm(false)}
                className="cancel-btn"
              >
                No
              </button>
            </div>
          </div>
        ) : (
          <FiLogOut
            size={24}
            color="black"
            onClick={() => setShowLogoutConfirm(true)}
            className="logout-icon"
          />
        )}
      </div>
    </div>
  );
};

export default Top;
