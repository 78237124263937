import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import './Header.css';
import logo from '../assets/logo.svg'; // Correct path to the logo
import menuIcon from '../assets/menu.svg'; // Correct path to the menu icon

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCorporateOpen, setIsCorporateOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(prev => !prev);
  const toggleCorporate = () => setIsCorporateOpen(prev => !prev);
  const toggleServices = () => setIsServicesOpen(prev => !prev);

  const closeMenu = () => {
    setIsMenuOpen(false);
    setIsCorporateOpen(false);
    setIsServicesOpen(false);
  };

  return (
    <nav>
      <div className="wrapper">
        <div className="logo">
          <img src={logo} alt="Cureclix Logo" className="logo-icon" />
          <Link to="/">Cureclix</Link> {/* Changed to Link */}
        </div>

        <div className="btn menu-btn" onClick={toggleMenu}>
          <img src={menuIcon} alt="Menu Icon" className="menu-icon" />
        </div>

        <ul className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
          <li><Link to="/" onClick={closeMenu}>Home</Link></li>
          <li><Link to="/about" onClick={closeMenu}>About</Link></li>

          <li className={`dropdown ${isCorporateOpen ? 'open' : ''}`}>
            <a href="#" onClick={toggleCorporate}>Corporate Login</a>
            {isCorporateOpen && (
              <ul className="drop-menu">
                <li><Link to="/login" onClick={closeMenu}>Doctor</Link></li> {/* Changed to Link */}
                {/* <li><Link to="/login" onClick={closeMenu}>Reception</Link></li> */}
                {/* <li><Link to="/login" onClick={closeMenu}>Medical</Link></li> */}
                {/* <li><Link to="/login" onClick={closeMenu}>Test & Imaging</Link></li> */}

              </ul>
            )}
          </li>

          <li className={`dropdown mega ${isServicesOpen ? 'open' : ''}`}>
            <a href="#" onClick={toggleServices}>Services</a>
            {isServicesOpen && (
              <div className="mega-box">
                <div className="content">
                  <div className="row">
                    <img src="https://fadzrinmadu.github.io/hosted-assets/responsive-mega-menu-and-dropdown-menu-using-only-html-and-css/img.jpg" alt="" />
                  </div>
                  {renderServiceSections(closeMenu)}
                </div>
              </div>
            )}
          </li>

          <li><Link to="/gallery" onClick={closeMenu}>Gallery</Link></li>
        </ul>
      </div>
    </nav>
  );
};

const renderServiceSections = (closeMenu) => {
  const sections = [
    {
      header: 'Web & App Services',
      links: ['Websites', 'Mobile Apps', 'Softwares', 'EHR & SRM']
    },
    {
      header: 'Graphic Design',
      links: ['Logo & Branding', 'Professional Posters', 'Video Services', 'Media Management']
    },
    {
      header: 'Marketing Tools',
      links: ['PPC Campaigns', 'Local SEO', 'Data Analysis', 'Patient Reviews']
    }
  ];

  return sections.map((section, index) => (
    <div className="row" key={index}>
      <header>{section.header}</header>
      <ul className="mega-links">
        {section.links.map((link, linkIndex) => (
          <li key={linkIndex}><a href="#" onClick={closeMenu}>{link}</a></li>
        ))}
      </ul>
    </div>
  ));
};

export default Header;
