// firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCWyMJjW7M81X6Fw8Pf7GuNoBvYQBnWWyc",
  authDomain: "cureclix-5c29a.firebaseapp.com",
  projectId: "cureclix-5c29a",
  storageBucket: "cureclix-5c29a.appspot.com",
  messagingSenderId: "571106467514",
  appId: "1:571106467514:web:a212dddd844dabfb2cec83",
  measurementId: "G-SL4V9LHY8L"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and Firestore
const auth = getAuth(app);
const firestore = getFirestore(app);

export { auth, firestore };