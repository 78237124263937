import React from "react";
import { Nav } from "react-bootstrap";
import { 
  FaHome, 
  FaBookMedical, 
  FaUserInjured, 
  FaChartLine, 
  FaUserCog // Import FaUserCog for Profile/Settings icon
} from "react-icons/fa";
import "./Sidebar.css";

const Sidebar = ({ isCollapsed, onMenuClick, showAppointments, showPatients }) => {
  const navItems = [
    {
      label: "Home",
      icon: <FaHome />,
      link: "Home",
      visible: true,
    },
    {
      label: "Appointments",
      icon: <FaBookMedical />,
      link: "Appointments",
      visible: showAppointments,
    },
    {
      label: "Patients",
      icon: <FaUserInjured />,
      link: "Patients",
      visible: showPatients,
    },
    {
      label: "Data Analysis",
      icon: <FaChartLine />,
      link: "Data",
      visible: true,
    },
    {
      label: "Profile",
      icon: <FaUserCog />, // Add Settings icon for Profile
      link: "Profile",
      visible: true, // Always visible
    },
  ];

  return (
    <div className={`sidebar ${isCollapsed ? "collapsed" : ""}`}>
      <Nav className="flex-column">
        {navItems.map(
          (item, index) =>
            item.visible && (
              <Nav.Item key={index}>
                <a
                  href="#"
                  className="nav-link"
                  onClick={() => onMenuClick(item.link)}
                >
                  {item.icon} {!isCollapsed && item.label}
                </a>
              </Nav.Item>
            )
        )}
      </Nav>
    </div>
  );
};

export default Sidebar;
