import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Top from "./Top";
import AppointmentStatus from "./AppointmentStatus";
import Booking from "./Booking";
import Patient from "./Patient";
import Data from "./Data";
import Profile from "./Profile"; // Import Profile.js
import "./Dashboard.css";

const Dashboard = () => {
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [activeSection, setActiveSection] = useState("Home");

  const toggleSidebar = () => setSidebarCollapsed((prev) => !prev);

  const handleMenuClick = (section) => {
    setActiveSection(section);
  };

  return (
    <div
      className="dashboard-wrapper"
      style={{
        backgroundColor: "white",
        minHeight: "100vh",
      }}
    >
      {/* Sidebar */}
      <div className={`sidebar ${isSidebarCollapsed ? "collapsed" : ""}`}>
        <Sidebar
          isCollapsed={isSidebarCollapsed}
          onMenuClick={handleMenuClick}
        />
      </div>

      {/* Sidebar Toggle Header */}
      <div
        className={`sidebar-toggle-header ${
          isSidebarCollapsed ? "collapsed" : ""
        }`}
      >
        <button
          className="dashboard-toggle"
          onClick={toggleSidebar}
          aria-label="Toggle Sidebar"
        >
          {isSidebarCollapsed ? "☰" : "✖"}
        </button>
        {!isSidebarCollapsed && <h2 className="sidebar-title">Cureclix</h2>}
      </div>

      {/* Main Content */}
      <div className={`main-content ${isSidebarCollapsed ? "collapsed" : ""}`}>
        <Top isSidebarCollapsed={isSidebarCollapsed} />
        <div className="dashboard-content">
          <div className="row">
            <div className="col-md-4">
              <div className="card">
                <div className="card-body">
                  {/* Conditional Rendering Based on Active Section */}
                  {activeSection === "Home" && (
                    <AppointmentStatus onNavigate={handleMenuClick} />
                  )}
                  {activeSection === "Appointments" && <Booking />}
                  {activeSection === "Patients" && <Patient />}
                  {activeSection === "Data" && <Data />}
                  {activeSection === "Profile" && <Profile />} {/* Render Profile Component */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
